<template>
  <!-- 个人中心 - 申请发票 -->
  <div class="collect">
    <div class="title">
      <p>申请发票</p>
      <div class="caozuo r">
        <div class="blue" @click="invoice.visible= true;">
          编辑信息</div>
      </div>
    </div>
    <div class="info">
      <ul>
        <li>
          <p class="l">开票属性：</p><span class="l">个人</span>
        </li>
        <li>
          <p class="l">联系人：</p>
          <span class="l">{{userInvoice ? userInvoice.contacts : '-'}}</span>
        </li>
        <li>
          <p class="l">身份证号：</p>
          <span class="l">{{userInvoice ? userInvoice.code : '-'}}</span>
        </li>
        <li>
          <p class="l">邮寄地址：</p>
          <span class="l">{{userInvoice ? userInvoice.address : '-'}}</span>
        </li>
        <li>
          <p class="l">联系电话：</p>
          <span class="l">{{userInvoice ? userInvoice.mobile :
          '-'}}</span>
        </li>
      </ul>
      <div class="contact">
        <img src="@/assets/image/kefu.jpg" alt="">
        <p>扫码联系客服 或拨打客服电话：400-990-0611</p>
      </div>
    </div>
    <edit-invoice v-if="invoice.visible" :invoiceInfo="userInvoice" @close="closeEdit" />
  </div>
</template>
<script>
import { GlobalApi } from "@/config/axios/api";
import EditInvoice from "@/components/personal/editInvoice";

export default {
  name: "Invoice",
  data () {
    return {
      type: 1,
      teamInvoice: {},
      userInvoice: {},
      invoice: {
        visible: false
      }
    }
  },
  components: { EditInvoice },
  created () {
    this.getInfo();
  },
  methods: {
    changeType (type) {
      this.type = type;
    },
    closeEdit (refresh) {
      if (refresh) {
        this.getInfo()
      }
      this.invoice.visible = false;
    },
    getInfo () {
      GlobalApi.getInvoice().then(res => {
        if (res.status == 1) {
          this.userInvoice = res.data;
        }
      })
    },
  }
};
</script>
<style scoped lang="scss">
.collect {
  padding: 28px 63px 0 40px;

  .title {
    @include flex(center, space-between);
    height: 42px;
    margin-bottom: 29px;

    p {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      line-height: 28px;
    }

    .caozuo {
      div {
        width: 90px;
        height: 42px;
        border-radius: 4px;
        border: 1px solid #222222;
        float: left;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1F1F1F;
        line-height: 40px;
      }
    }
  }

  .info {
    margin: 28px 0 0;

    ul {
      li {
        padding-bottom: 13px;
        margin-bottom: 15px;
        overflow: hidden;
        border-bottom: 1px solid #EAEDF0;

        &:last-of-type {
          margin-bottom: 23px;
        }

        p {
          width: 246px;
          padding-left: 4px;
          margin-top: 16px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1F1F1F;
          line-height: 22px;
        }

        span {
          margin-top: 16px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #484848;
          line-height: 22px;
        }

        .downdemo-type {
          text-align: center;
          width: 160px;
          height: 40px;
          background: #F6F7F9;
          border-radius: 20px;
          margin: 0 auto;
          position: relative;

          div {
            position: absolute;
            left: 2px;
            top: 2px;
            cursor: pointer;

            &:last-of-type {
              right: 2px;
              left: auto;
            }

            width: 80px;
            height: 36px;
            border-radius: 20px;
            font-family: PingFangSC-Regular,
            PingFang SC;
            font-weight: 400;
            color: #484848;
            font-size: 16px;
            line-height: 36px;

            &.active {
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #222222;
              background: #FFFFFF;
            }
          }
        }

      }
    }

    .contact {
      text-align: center;
      margin-top: 145px;

      img {
        width: 156px;
        height: 156px;
      }

      p {
        margin-top: 17px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7E8182;
        line-height: 20px;
      }

    }
  }

}
</style>
