<template>
	<!-- 编辑发票 -->
	<div class="downdemo banquan-dialog">
		<div class="downdemo-inner">
			<div class="title">编辑开票信息</div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="login-form" label-width="0"
				autocomplete="new-password">
				<el-form-item label="" prop="contacts">
					<div class="el-item-title">*联系人</div>
					<el-input type="text" autocomplete="new-password" v-model="ruleForm.contacts" :placeholder="'联系人'"></el-input>
				</el-form-item>
				<el-form-item label="" prop="code">
					<div class="el-item-title">*身份证号</div>
					<el-input type="text" autocomplete="new-password" v-model="ruleForm.code" :placeholder="'身份证号'"></el-input>
				</el-form-item>
				<el-form-item label="" prop="address">
					<div class="el-item-title">*邮寄地址</div>
					<el-input type="text" autocomplete="new-password" v-model="ruleForm.address" placeholder="邮寄地址">
					</el-input>
				</el-form-item>
				<el-form-item label="" prop="mobile">
					<div class="el-item-title">*联系电话</div>
					<el-input type="text" v-model="ruleForm.mobile" maxlength="11" auto-complete="on" class="mobile-input"
						placeholder="联系电话"></el-input>
				</el-form-item>
			</el-form>
			<el-button class="user-btn" @click="editMember" :disabled="loginStatus" :loading="loginStatus">
				确定
			</el-button>
			<div class="banquan-close" @click="$emit('close')"><span class="iconfont icon-close"></span></div>
		</div>
	</div>

</template>

<script>
import { GlobalApi } from "@/config/axios/api";

export default {
	name: 'editMember',
	props: ['invoiceInfo'],
	data () {
		var validateMobile = (rule, value, callback) => {
			if (!this.staticFunc.checkMobile(value)) {
				callback(new Error("请输入正确的手机号"));
			}
			callback();
		};
		return {
			ruleForm: {
				contacts: "",
				code: '',
				address: "",
				mobile: "",
			},
			timer: null,
			count: 0,
			loginStatus: false, // 点击按钮请求是， 需禁用
			timerStatus: false, // 点击按钮请求是， 需禁用
			rules: {
				code: [
					{ required: true, message: "请输入身份证号", trigger: "blur" },
				],
				address: [
					{ required: true, message: "请输入邮寄地址", trigger: "blur" }
				],
				contacts: [
					{ required: true, message: "请输入联系人", trigger: "blur" }
				],
				mobile: [
					{ required: true, message: "请输入联系电话", trigger: "blur" },
					{ validator: validateMobile, trigger: "blur" },
				],
			},
			teamInvoice: {},
			userInvoice: {},
		}
	},
	computed: {

	},
	mounted () {
		this.initData();
	},
	destroyed () {

	},
	methods: {
		initData () {
			if (this.invoiceInfo) {
				this.ruleForm.contacts = this.invoiceInfo.contacts;
				this.ruleForm.code = this.invoiceInfo.code;
				this.ruleForm.address = this.invoiceInfo.address;
				this.ruleForm.mobile = this.invoiceInfo.mobile;
			}
			this.$refs["ruleForm"].clearValidate();
		},
		editMember () {
			this.loginStatus = true;
			this.$refs["ruleForm"].validate((valid) => {
				if (valid) {
					GlobalApi.editInvoice(this.ruleForm).then(res => {
						if (res.status == 1) {
							this.$message.success('开票修改成功')
							this.$emit('close', true)
						} else {
							this.loginStatus = false;
						}
					})
				} else {
					this.loginStatus = false;
					return false;
				}
			});
		},
	},
}
</script>

<style lang="scss" scoped>
.downdemo {
	.downdemo-inner {
		width: 500px;
		background: #fff;
		border-radius: 8px;
		text-align: center;
		position: relative;
		padding: 0 40px;
		box-sizing: border-box;

		.title {
			margin: 47px 0 20px;
			font-size: 22px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #3B3946;
			line-height: 30px;
		}

		.downdemo-type {
			width: 180px;
			height: 38px;
			background: #F6F7F9;
			border-radius: 20px;
			margin: 0 auto;
			position: relative;

			li {
				position: absolute;
				left: 2px;
				top: 2px;
				cursor: pointer;

				&:last-of-type {
					right: 2px;
					left: auto;
				}

				width: 88px;
				height: 34px;
				border-radius: 20px;
				color: #484848;
				line-height: 34px;
				font-size: 14px;
				font-family: PingFangSC-Regular,
				PingFang SC;
				font-weight: 400;
				color: #222222;

				&.active {
					font-family: PingFangSC-Medium, PingFang SC;
					background: #FFFFFF;
					font-weight: 500;
					color: #484848;
				}
			}
		}

		.login-form {
			margin-top: 30px;

			.el-form-item {
				margin-bottom: 20px;

				.el-item-title {
					font-size: 14px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #3B3946;
					line-height: 20px;
					line-height: 40px;
					float: left;
					width: 110px;
					text-align: right;
					padding-right: 12px;
				}

				.el-input,
				.el-select {
					float: left;
					width: 72%;
					height: 40px;

					::v-deep .el-input__inner {
						padding-left: 12px;
						height: 40px;
						border: 1px solid #CED0D4;
					}
				}

				.el-textarea {
					float: left;
					width: 78%;
				}

				::v-deep .el-form-item__error {
					padding-left: 110px;
				}

				.send-btn {
					position: absolute;
					right: 10px;
					top: 0;
					height: 40px;
					background: none;
					outline: none;
					border: none;
					font-size: 12px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #222222;
					padding-right: 13px;
				}
			}
		}

		.user-btn {
			width: 310px;
			height: 50px;
			background: #222222;
			border-radius: 4px;
			margin: 24px auto 41px;
			font-size: 18px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #FFFFFF;
			letter-spacing: 4px;
			cursor: pointer;
		}

	}
}
</style>
